<template>
	<div class="e_meeting">
    <img class="e_meeting_cover" src="@/assets/emeeting-cover.png" alt="">
    <div class="e_meeting_footer"></div>
    <div class="e_meeting_footer foot">
      <base-open-webapp :path="sslPath">
        <van-button block type="primary" round>进入大会</van-button>
      </base-open-webapp>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = '中国卒中学会第九届学术年会暨天坛脑血管病会议2023'
  },
  computed: {
    sslPath () {
      return `pages/other/web-view/index?url=${encodeURIComponent('https://large-platform-mobile.medical-force.cn/emeeting/#/?param=1668286902040862722')}&h5Type=emeeting`
    },
  }
};
</script>

<style scoped lang="less">
.e_meeting {
  position: relative;
  .e_meeting_cover {
    width: 100%;
    object-fit: contain;
  }

  .e_meeting_footer {
    height: 100px;
    width: 100%;
    &.foot {
      box-sizing: border-box;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 20px;
      background-color: #fff;
      box-shadow: -10px 0 10px #ddd;
      border-radius: 10px 10px 0 0;
    }
  }
}
 
</style>
